/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import { typeToPageMap } from '../../utils/constants';
import './footer.css';

/* footer change */
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  '& .footerLinks': {
    color: '#fff',
    textDecoration: 'none',
    padding: '0px 10px'
  },
  '& .pipe': {
    color: '#fff'
  },
  '& .copy': {
    color: '#fff',
    textAlign: 'right'

  },
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap'
}));

export default function Footer({ legalFootNote }) {
  const [date, setDate] = React.useState(new Date())
  const data = useStaticQuery(graphql`
  query Footer {
    contentfulRanFooter(isDisabled: {ne: true}) {
      title
      link {
        title
        link {
          ... on ContentfulInternalLink {
            openInANewTab
            entryTitle
            refPage {
              __typename
              ... on ContentfulPageUtility {
                id
                entryTitle
                contentful_id
                type
                slug
              }
              ... on ContentfulPageKnowledge {
                id
                title
                slug
                contentful_id
                category
              }
              ... on ContentfulPageEvent {
                id
                title
                slug
                contentful_id
                eventTitle
                category
              }
              ... on ContentfulPageProgram {
                id
                category
                contentful_id
                slug
                programTitle
              }
            }
          }
          ... on ContentfulCompExternalLink {
            id
            openInANewTab
            title
            url {
              url
            }
          }
        }
      }
    }
  }
  `);

  return (
    <>
      <Helmet>

        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
          var MY_DOMAIN = "gdprdemoclient_api_banner.com";
          var REQUIRE_USER_EXPRESSED_PERMISSION = true;
          var _STATE = {};

          function activateElement(list){
            if(!(list instanceof Array || list instanceof NodeList)) throw "Illegal argument - must be an array";
            console.log("activating", list);
            for(var item,i=list.length;i-- >0;){
              item = list[i];
              item.class = "trustecm_done";
              switch(item.nodeName.toLowerCase()){
                case "script":
                  var z = item.getAttribute("thesrc");
                  if(z){
                    var y = document.createElement("script");
                    y.src = z;
                    y.async = item.async;
                    item.parentNode.insertBefore(y,item);
                  }else eval(item.text || item.textContent || item.innerText);
              }
            }
          }
          _STATE.i = setInterval(runOnce,10);`
          }}
        />
      </Helmet>
      <div id='consent_blackbar'>   </div>
      {
        legalFootNote && (
          <Box sx={{ flexGrow: 1, backgroundColor: '#F0F0F0' }} p={{ xs: '20px', md: '10px 106px 10px 106px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item>
                  {legalFootNote}
                </Item>
              </Grid>
            </Grid>
          </Box>
        )
      }
      <Box
        sx={{ flexGrow: 1, backgroundColor: '#030330' }}
        p={{ xs: '15px 5px', md: '15px 106px' }}
      >
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12} pl={{ sm: '0px !important' }}>
            <Item>
              {
                data?.contentfulRanFooter?.link?.map((e, i) => {
                  const pageTypeName = e?.link?.refPage?.__typename;
                  return (
                    <>
                      {/* internal page link */}
                      {e?.link?.refPage ? e?.link?.refPage?.slug && e?.link?.entryTitle && <><Link href={typeToPageMap[pageTypeName] + e.link.refPage.slug} target={e?.link?.openInANewTab ? '_blank' : ''} className='footerLinks' sx={{ color: '#fff', fontSize: '14px' }} m={{ xs: '0px 5px', md: '0px 20px' }}> {e?.link?.entryTitle}</Link><Link sx={{ color: '#FFFFFF' }} className='pipe' target={e?.link?.openInANewTab ? '_blank' : ''}>{i + 1 !== data?.contentfulRanFooter?.link.length ? '|' : ''}</Link></>
                        : e.link?.url?.url && e?.link?.title && <><Link href={e.link?.url?.url} target={e?.link?.openInANewTab ? '_blank' : ''} className='footerLinks' sx={{ color: '#fff', fontSize: '14px' }} m={{ xs: '0px 5px', md: '0px 20px' }}> {e?.link?.title}</Link><Link sx={{ color: '#FFFFFF' }} className='pipe' target={e?.link?.openInANewTab ? '_blank' : ''}>{i + 1 !== data?.contentfulRanFooter?.link.length ? '|' : ''}</Link></>}
                    </>
                  );
                })
              }
              <span className='pipe'>| </span>
              <span id='teconsent' className='footerLinks' />
              <Helmet>
                <script async='async' src='//consent.trustarc.com/notice?domain=realogyleadsgroup.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&privacypolicy=https%3A%2F%2Fwww.realogy.com%2Fprivacy-statement%2F' crossOrigin />
              </Helmet>
            </Item>
          </Grid>
          <Grid item xs={12} className='copy'>
            <Item>
              <Typography variant='body1' className='copy'>
                © {date.getFullYear()} Anywhere Leads Inc.
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
