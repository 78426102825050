import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Link } from 'gatsby';

const HeaderBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1300,
  ...theme.typography.body2,
  '& .list': {
    li: {
      borderBottom: '1px solid gray',
      a: {
        color: 'black'
      }
    }
  },
  '& .headerSideNav': {
    backgroundColor: '#fff',
    height: '100%',
    width: '80vw'
  },
  '& .container': {
    backgroundColor: 'rgb(88 88 88 / 43%)',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
    position: 'fixed',
    zIndex: '995',
    right: '0px',
    top: '0px',
    transition: '1s',
    height: '100%'
  },
  '& .toolbar': {
    '@media all': {
      padding: 0
    }
  },
  '& .MuiBreadcrumbs-separator': {
    color: '#fff'
  },
  '& .headerAppBar': {
    position: 'inherit',
    flexGrow: 1,
    backgroundColor: '#030330',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  '& .headerMobileView': {
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  '& .headerButon': {
    marginLeft: '16px'
  },
  '& .headerH6': {
    flexGrow: 1
  },
  '& .gridContainer': {
    flexGrow: 1
    // backgroundColor: '#383838'
  },
  '& .IconAlign': {
    verticalAlign: 'middle'
  },
  '& .menuStyle': {
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  '& .menuStyle1': {
    textAlign: 'right',
    marginRight: '25px',
    fontWeight: '300'
  },
  '& .menuStyle2': {
    textAlign: 'right'
  },
  '& .calender': {
    display: 'inline',
    color: '#fff',
    marginRight: '20px'
  },
  '& .buttonLink': {
    textDecoration: 'none'
  },
  // '& .css-1rcw1w0-MuiPaper-root-MuiAppBar-root': {
  //   backgroundColor: '#030330 !important'
  // },
  '& .closeIcon': {
    marginRight: '10px',
    marginTop: '20px',
    fontSize: '24px'
  },
  '& .displayNone': {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  color: 'white',
  paddingBottom: '10px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '& .MuiBreadcrumbs-separator': {
    color: '#fff'
  }
}));

const MenuLink = styled(Link)(({ theme }) => ({
  textTransform: 'none',
  marginRight: '16px',
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    color: '#000'
  },
  [theme.breakpoints.down('md')]: {
    marginRight: '0px'
  },
  textDecoration: 'none',
  '&.active > button': {
    fontWeight: 900
  },
  '& > button': {
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  }
}));

const BreadcrumbLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  color: '#fff'
}));

export {
  HeaderBox,
  Item,
  MenuLink,
  BreadcrumbLink
};
