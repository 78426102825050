import * as React from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useIdleTimer } from 'react-idle-timer';

import ranTheme from './theme';
import Header from '../header';
import Footer from '../footer';

// Okta
import { useUserContext } from '../../context/auth-context';
import { getUserDetails, startOktaService, checkForActiveSession, closeSession } from '../../utils/okta-util';
import Loading from '../body-loading';

import './layout.css';

function Layout({ legalFootNote, children, pageName, eventDetails = null, location, isMarketingMaterials, is404 = false }) {
  const isPublic = location.pathname === '/';
  const isUtility = location.pathname.includes('/page/');
  const isInternalPage = !isPublic && !isUtility && !is404;

  const { state: authState, dispatch: authDispatch } = useUserContext();
  const [authenticated, setAuthenticated] = React.useState(false);

  const handleOnIdle = () => {
    // Only logout if logged in...
    if (!authState?.isAuthenticated || !authState?.user) {
      return;
    }
    // eslint-disable-next-line no-use-before-define
    getLastActiveTime();
    closeSession(authState.authClient);
    authDispatch({
      type: 'logout'
    });
  };

  const { getLastActiveTime } = useIdleTimer({
    // timeout: 1000 * 60 * 60, // One hour timeout ...
    timeout: 1000 * 60 * 60, // One hour timeout ...
    // eslint-disable-next-line no-use-before-define
    onIdle: handleOnIdle,
    debounce: 500
  });

  const setRedirectUrl = () => {
    if (location.pathname !== '/') {
      sessionStorage.setItem('redirectUrl', location.pathname);
    }
  };

  const getUserDetailsFromOkta = async () => {
    if (authState?.isAuthenticated && authState?.user) {
      setAuthenticated(true);
      startOktaService(authState.authClient);
      return;
    }

    // Allow navigation to /page
    if (isUtility && !localStorage.getItem('user')) {
      setRedirectUrl();
      return;
    }

    if (is404 && !localStorage.getItem('user')) {
      setRedirectUrl();
      return;
    }

    if (!localStorage.getItem('user')) {
      setRedirectUrl();
      if (location.pathname !== '/') {
        authDispatch({
          type: 'redirect'
        });
      } else {
        navigate('/');
      }
      return;
    }

    const isSessionActive = await checkForActiveSession(authState.authClient);

    if (!isSessionActive) {
      setRedirectUrl();
      if (location.pathname !== '/') {
        authDispatch({
          type: 'redirect'
        });
      } else {
        navigate('/');
      }
      return;
    }

    if (!authState.user) {
      // Getting and Setting user

      // Start Okta service
      startOktaService(authState.authClient);
      setAuthenticated(true);

      const user = await getUserDetails(authState.authClient);
      authDispatch({
        type: 'user',
        payload: {
          user
        }
      });
    }
  };

  React.useEffect(() => {
    getUserDetailsFromOkta();
    if (authenticated && location.pathname === '/') {
      navigate('/home');
    }
  }, [authenticated]);

  return (
    <ThemeProvider theme={ranTheme}>
      <CssBaseline />

      {/* Public, Utility and 404 and not logged In */}
      {(isPublic || isUtility || is404) && !authenticated && (
        <>
          <Header
            pathName={location.pathname}
            pageTitle={pageName}
            eventDetails={eventDetails}
            isMarketingMaterials={isMarketingMaterials}
          />
          <main className='main'>{children}</main>
          <Footer legalFootNote={legalFootNote} />
        </>
      )}

      {/* Public, Utility and 404 and logged In */}
      {(isPublic || isUtility || is404) && authenticated && (
        <>
          <Header
            pathName={location.pathname}
            pageTitle={pageName}
            eventDetails={eventDetails}
            isMarketingMaterials={isMarketingMaterials}
          />
          <main className='main'>{children}</main>
          <Footer legalFootNote={legalFootNote} />
        </>
      )}

      {/* Authenticated and Internal */}
      {isInternalPage && authenticated && (
        <>
          <Header
            pathName={location.pathname}
            pageTitle={pageName}
            eventDetails={eventDetails}
            isMarketingMaterials={isMarketingMaterials}
          />
          <main className='main'>{children}</main>
          <Footer legalFootNote={legalFootNote} />
        </>
      )}

      {/* Logged In && not authenticated */}
      {!isInternalPage && !isUtility && !is404 && !isPublic && !authenticated && (
        <Loading />
      )}

    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape.isRequired,
  legalFootNote: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  eventDetails: PropTypes.shape({
    addToCalender: PropTypes.string.isRequired,
    registerLink: PropTypes.string.isRequired
  }),
  isMarketingMaterials: PropTypes.bool,
  is404: PropTypes.bool
};

Layout.defaultProps = {
  legalFootNote: null,
  eventDetails: null,
  isMarketingMaterials: false,
  is404: false
};

export default Layout;
