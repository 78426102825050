import ImageIcon from '@mui/icons-material/Image';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GridOnIcon from '@mui/icons-material/GridOn';

export const Constants = {
  program: 'Referral Sources',
  event: 'Events',
  'knowledge-center': 'Knowledge Center',
  search: 'Search'
};

export const listingUrlMap = {
  Event: '/event',
  Program: '/program',
  Knowledge: '/knowledge-center'
};

export const iconFileTypesMap = {
  'application/pdf': PictureAsPdfIcon,
  'image/png': ImageIcon,
  'image/svg': InsertDriveFileIcon,
  'application/zip': FolderZipIcon,
  'image/webp': ImageIcon,
  'application/CDFV2': ArticleIcon,
  'application/vnd.ms-powerpoint': ArticleIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': GridOnIcon
};

export const mapIconBasedOnFileType = assetData => {
  const icon = assetData?.downloadLink?.file?.contentType;
  const iconType = () => iconFileTypesMap[icon];
  const DynamicIcon = iconType();
  return { DynamicIcon, icon };
};

export const typeToPageMap = {
  ContentfulPageProgram: '/program/',
  ContentfulPageEvent: '/event/',
  ContentfulPageKnowledge: '/knowledge-center/',
  ContentfulPageUtility: '/page/'
};
