import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { navigate } from 'gatsby';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { useUserContext } from '../../context/auth-context';
import { Constants, typeToPageMap } from '../../utils/constants';
//import logo from '../../../static/logo.png';
//import logo from '../../../static/AnywhereLeadsNetwork_logo_260x77.png';
import logo from '../../../static/AnywhereLeadsNetwork_logo_New_260x77.png';
import SearchBar from '../search-bar';
import { MenuLink, BreadcrumbLink, HeaderBox, Item } from './style';
import { closeSession } from '../../utils/okta-util';

const drawerWidth = 250;

function Header({ pathName: _pathName, pageTitle: _pageTitle, eventDetails, isMarketingMaterials }) {
  const pageTypeName = eventDetails?.registerLink?.link?.refPage?.__typename;

  const { state: { authClient, isAuthenticated, user }, dispatch: authDispatch } = useUserContext();

  const [isLoggedIn, setIsLoggedIn] = React.useState(null);

  //code for profile starts
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async event => {
    event.stopPropagation();
    await closeSession(authClient);
    authDispatch({
      type: 'logout'
    });
    handleClose();
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  //code for profile ends
  const [pathList, setPathList] = React.useState([]);
  const [active, setActive] = React.useState(null);

  // For SearchBar
  const [isSearchVisible, setIsSearchVisible] = React.useState(false);

  const [programOverview, setProgramOverview] = React.useState(false);

  const { window } = {};
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isLinkActive = ({ href, isPartiallyCurrent }) => {
    if (isPartiallyCurrent) {
      setActive(href.replace('/', ''));
    }
  };

  const goToOverview = event => {
    event.preventDefault();
    if (_pathName.includes('marketing-materials')) {
      navigate(_pathName.replace('/marketing-materials', ''));
    }
  };

  const goToMarketingMaterials = event => {
    event.preventDefault();
    if (!_pathName.includes('marketing-materials')) {
      const pathNameWithoutSlash = _pathName.substr(_pathName.length - 1, 1) === '/'
        ? _pathName.substring(_pathName.length - 1, 1)
        : _pathName;
      if (pathNameWithoutSlash.charAt(0) === '/') {
        navigate(`${pathNameWithoutSlash}/marketing-materials`);
      } else {
        navigate(`/${pathNameWithoutSlash}/marketing-materials`);
      }
    }
  };

  const SearchBarComp = (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'absolute'
      }}
    >
      {
        _pageTitle !== '404' && !_pathName.includes('/page/') && (
          <SearchBar isOpen={isSearchVisible} close={() => setIsSearchVisible(false)} />
        )
      }
    </Box>
  );

  const oktaLogin = async () => {
    authDispatch({
      type: 'redirect'
    });
  };

  React.useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  React.useEffect(() => {
    if (_pathName) {
      const paginationItems = _pathName
        .split('/')
        .filter(i => i !== '')
        .filter(i => i)
        .filter(i => Constants[i])
        .map(item => ({
          name: Constants[item],
          link: item
        }));
      setPathList(paginationItems);

      if (active === 'program' && _pageTitle !== '404') {
        if (_pathName.includes('/marketing-materials')) {
          setProgramOverview(false);
        } else {
          setProgramOverview(true);
        }
      }
    }
  }, [_pathName, active]);

  return (
    <HeaderBox className='headerBox'>
      {/* Header */}

      <Box sx={{ flexGrow: 1 }} className='headerMobileView'>
        <Box sx={{ display: 'flex' }}>

          <AppBar
            sx={{
              position: 'inherit',
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` }
            }}
          >
            <Toolbar>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid>
                  <img alt='RAN Logo' src={logo} height={80} sx={{ flexGrow: 1 }} />
                </Grid>
                <Grid>
                  {isLoggedIn && (
                    <Box sx={{ display: 'inline-block' }}>
                      <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ mr: 2 }}
                        onClick={() => {
                          setIsSearchVisible(!isSearchVisible);
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Box>
                  )}
                  <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>

            </Toolbar>
            <Grid container className='mobileGridContainer' alignItems='end' justifyContent='space-between'>
              {
                active === 'program' && _pageTitle && isMarketingMaterials && (
                  <Grid item xs={12} alignItems='center' textAlign='right'>
                    <Button
                      color='inherit'
                      onClick={goToOverview}
                      sx={{ textTransform: 'none', mr: '15px', fontWeight: programOverview ? 'bold' : 'normal' }}
                    >
                      Overview
                    </Button>
                    <Button
                      color='inherit'
                      onClick={goToMarketingMaterials}
                      sx={{ textTransform: 'none', fontWeight: !programOverview ? 'bold' : 'normal' }}
                    >
                      Marketing Materials
                    </Button>
                  </Grid>
                )
              }

              {
                active === 'event' && eventDetails && eventDetails.category !== 'Past Event' && (
                  <Grid container xs={12} sm={4} sx={{ p: '0px 30px' }} alignItems='center' textAlign='right' paddingBottom='7px !important' justifyContent={{ xs: 'space-between', sm: 'flex-end' }}>
                    <a href={eventDetails.addToCalender.url.url ? eventDetails.addToCalender.url.url : ''} target='_blank' title='Add to Calender' rel='noreferrer' className='calender'>
                      <CalendarTodayIcon sx={{ mr: '3px', height: 15, position: 'relative', top: '2px' }} />
                      Add to Calender
                    </a>
                    {eventDetails.registerLink && eventDetails.registerLink?.link && eventDetails.registerLink?.link?.url
                      ? (
                        <a href={eventDetails.registerLink.link.url.url ? eventDetails.registerLink.link.url.url : ''} target={eventDetails?.registerLink?.link?.openInANewTab ? '_blank' : ''} title='Register for Event' rel='noreferrer' className='buttonLink'>
                          <Button color='primary' size='small' sx={{ p: '0 8px', fontSize: '0.8rem' }}>Register for Event</Button>
                        </a>
                      )
                      : (eventDetails.registerLink?.link?.refPage && eventDetails.registerLink?.link?.refPage?.slug
                      && (
                        <a href={eventDetails.registerLink.link.refPage.slug ? (typeToPageMap[pageTypeName] + eventDetails.registerLink.link.refPage.slug) : ''} target={eventDetails?.registerLink?.link?.openInANewTab ? '_blank' : ''} title='Register for Event' rel='noreferrer' className='buttonLink'>
                          <Button color='primary' size='small' sx={{ p: '0 8px', fontSize: '0.8rem' }}>Register for Event</Button>
                        </a>
                      ))}
                  </Grid>
                )
              }
            </Grid>
          </AppBar>

          {/* Mobile Drawer */}
          <Box
            component='nav'
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label='mailbox folders'
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant='temporary'
              anchor='right'
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, paddingTop: '60px' }
              }}
            >
              <Grid
                container
                direction='row'
                justifyContent='flex-end'
                p='40px 30px 9px 0px'
              >
                <CloseIcon
                  fontSize='large'
                  onClick={handleDrawerToggle}
                  className='closeIcon'
                />
              </Grid>

              {/* Authenticated Menu */}
              {isLoggedIn && (
                <Grid container p={{ xs: '20px' }} alignItems='center'>

                  <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    sx={{ mr: 2 }}
                    onClick={() => navigate('/home')}
                  >
                    <HomeOutlinedIcon />
                  </IconButton>
                  <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                  />
                  <PersonIcon />
                  <Button
                    color='inherit'
                    id='fade-button'
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <Typography variant='body2'>{user?.given_name}</Typography>
                  </Button>
                  <Menu
                    id='fade-menu'
                    MenuListProps={{
                      'aria-labelledby': 'fade-button'
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    sx={{ width: 230 }}
                  >
                    <Box sx={{ width: 230 }}>
                      <Typography variant='body2' paddingLeft='15px'>{user?.given_name}</Typography>
                      <MenuItem onClick={handleLogout}>SignOut</MenuItem>
                    </Box>
                  </Menu>
                </Grid>
              )}

              {/* Public Menu */}
              {isLoggedIn === false && (
                <>
                  <ListItem>
                    <MenuLink to='/#aboutUs' activeClassName='active' getProps={isLinkActive} className={active === 'program' ? 'active' : ''}>
                      <Button style={{ justifyContent: 'left' }} onClick={handleDrawerToggle} color='inherit'>About Us</Button>
                    </MenuLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <MenuLink to='/#faq' activeClassName='active' getProps={isLinkActive} className={active === 'event' ? 'active' : ''}>
                      <Button style={{ justifyContent: 'left' }} onClick={handleDrawerToggle} color='inherit'>FAQs</Button>
                    </MenuLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <MenuLink activeClassName='active' getProps={isLinkActive} className={active === 'event' ? 'active' : ''}>
                      <Button style={{ justifyContent: 'left' }} onClick={oktaLogin} color='inherit'>Sign In</Button>
                    </MenuLink>
                  </ListItem>
                </>
              )}

              {/* Authenticated Menu */}
              {isLoggedIn && (
                <List className='list'>
                  <ListItem>
                    <MenuLink to='/program' activeClassName='active' getProps={isLinkActive} className={active === 'program' ? 'active' : ''}>
                      <Button style={{ justifyContent: 'left' }} color='inherit'>Referral Sources</Button>
                    </MenuLink>
                  </ListItem>
                  <Divider />
                  <ListItem paddingLeft='10px !important'>
                    <MenuLink to='/event' activeClassName='active' getProps={isLinkActive} className={active === 'event' ? 'active' : ''}>
                      <Button style={{ justifyContent: 'left' }} color='inherit'>Events</Button>
                    </MenuLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <MenuLink to='/knowledge-center' activeClassName='active' getProps={isLinkActive} className={active === 'knowledge-center' ? 'active' : ''}>
                      <Button style={{ justifyContent: 'left' }} color='inherit'>Knowledge Center</Button>
                    </MenuLink>
                  </ListItem>
                </List>
              )}

            </Drawer>
          </Box>

        </Box>
      </Box>

      <AppBar className='headerAppBar'>
        <Container className='headerContainer'>
          <Toolbar className='toolbar'>
            <img alt='RAN Logo' src={logo} height={80} />
            <Typography variant='h6' component='div' className='headerH6' />

            {/* Utility Page Sections */}
            {isLoggedIn === false && (
              <>
                <MenuLink to='/#aboutUs' activeClassName='active' getProps={isLinkActive} className={active === 'program' ? 'active' : ''}>
                  <Button color='inherit'>About Us</Button>
                </MenuLink>
                <MenuLink to='/#faq' activeClassName='active' getProps={isLinkActive} className={active === 'event' ? 'active' : ''}>
                  <Button color='inherit'>FAQs</Button>
                </MenuLink>
                <Button size='small' variant='contained' className='assetCardActionButton1' onClick={oktaLogin}>Sign In</Button>
              </>
            )}

            {/* If / or 404 and auth */}
            {isLoggedIn && (
              <>
                <MenuLink to='/program' activeClassName='active' getProps={isLinkActive} className={active === 'program' ? 'active' : ''}>
                  <Button color='inherit'>Referral Sources</Button>
                </MenuLink>
                <MenuLink to='/event' activeClassName='active' getProps={isLinkActive} className={active === 'event' ? 'active' : ''}>
                  <Button color='inherit'>Events</Button>
                </MenuLink>
                <MenuLink to='/knowledge-center' activeClassName='active' getProps={isLinkActive} className={active === 'knowledge-center' ? 'active' : ''}>
                  <Button color='inherit'>Knowledge Center</Button>
                </MenuLink>
                <IconButton
                  size='large'
                  edge='start'
                  color='inherit'
                  aria-label='menu'
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setIsSearchVisible(!isSearchVisible);
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  size='large'
                  edge='start'
                  color='inherit'
                  aria-label='menu'
                  sx={{ mr: 2 }}
                  onClick={() => navigate('/home')}
                >
                  <HomeOutlinedIcon />
                </IconButton>
                <IconButton
                  size='large'
                  edge='start'
                  color='inherit'
                  aria-label='menu'
                />
                <Button
                  color='inherit'
                  id='fade-button'
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <PersonIcon />
                  <Typography variant='body2' sx={{ pl: 1, textTransform: 'initial' }}>{user?.given_name}</Typography>
                </Button>
                <Menu
                  id='fade-menu'
                  MenuListProps={{
                    'aria-labelledby': 'fade-button'
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  sx={{ width: 230 }}
                >
                  <Box sx={{ width: 230 }}>
                    <Typography variant='body2' paddingLeft='15px'>{user?.name}</Typography>
                    <Divider />
                    <MenuItem onClick={handleLogout}>Signout</MenuItem>
                  </Box>
                </Menu>
              </>
            )}
          </Toolbar>

          {/* Pagination */}

          <Grid container className='gridContainer' alignItems='end' justifyContent='space-between'>
            {_pathName !== '/' && _pageTitle !== '404' && !_pathName.includes('/page/') && (
              <Grid item xs={7} className='displayNone'>
                <Item>
                  <Breadcrumbs aria-label='breadcrumb' separator='>' p={{ xs: '0', md: '5px 0 0' }}>
                    {_pathName !== '/' && (
                      <BreadcrumbLink underline='hover' color='inherit' to='/home'>
                        Home
                      </BreadcrumbLink>
                    )}
                    {
                      _pathName !== '/' && pathList.map(item => (
                        <BreadcrumbLink underline='hover' to={`/${item.link}`}>
                          {item.name}
                        </BreadcrumbLink>
                      ))
                    }
                    {
                      _pageTitle && <Typography color='#fff' variant='body2' sx={{ position: 'relative', top: '2px' }}>{_pageTitle}</Typography>
                    }
                  </Breadcrumbs>
                </Item>
              </Grid>
            )}

            {
              active === 'program' && _pageTitle && isMarketingMaterials && (
                <Grid item xs={5} lg={4} alignItems='center' textAlign='right'>
                  <Button
                    color='inherit'
                    onClick={goToOverview}
                    sx={{ textTransform: 'none', mr: '15px', fontWeight: programOverview ? 'bold' : 'normal' }}
                  >
                    Overview
                  </Button>
                  <Button
                    color='inherit'
                    onClick={goToMarketingMaterials}
                    sx={{ textTransform: 'none', fontWeight: !programOverview ? 'bold' : 'normal' }}
                  >
                    Marketing Materials
                  </Button>
                </Grid>
              )
            }

            {
              active === 'event' && eventDetails && eventDetails.category !== 'Past Event' && (
                <Grid item xs={12} md={4} alignItems='center' textAlign='right'>
                  <Typography paddingBottom='8px'>
                    <a href={eventDetails.addToCalender.url.url ? eventDetails.addToCalender.url.url : ''} target={eventDetails?.addToCalender?.openInANewTab ? '_blank' : ''} title='Add to Calender' rel='noreferrer' className='calender'>
                      <CalendarTodayIcon sx={{ mr: '3px', height: 15, position: 'relative', top: '2px' }} />
                      Add to Calender
                    </a>
                    {eventDetails.registerLink && eventDetails.registerLink?.link && eventDetails.registerLink?.link?.url
                      ? (
                        <a href={eventDetails.registerLink.link.url.url ? eventDetails.registerLink.link.url.url : ''} target={eventDetails?.registerLink?.link?.openInANewTab ? '_blank' : ''} title='Register for Event' rel='noreferrer' className='buttonLink'>
                          <Button color='primary' size='small' sx={{ p: '0 8px', fontSize: '0.8rem' }}>Register for Event</Button>
                        </a>
                      )
                      : (eventDetails.registerLink?.link?.refPage
                      && (
                        <a href={eventDetails.registerLink.link.refPage.slug ? (typeToPageMap[pageTypeName] + eventDetails.registerLink.link.refPage.slug) : ''} target={eventDetails?.registerLink?.link?.openInANewTab ? '_blank' : ''} title='Register for Event' rel='noreferrer' className='buttonLink'>
                          <Button color='primary' size='small' sx={{ p: '0 8px', fontSize: '0.8rem' }}>Register for Event</Button>
                        </a>
                      ))}
                  </Typography>
                </Grid>
              )
            }
          </Grid>

        </Container>
      </AppBar>

      <Fade in={isSearchVisible}>
        {SearchBarComp}
      </Fade>

    </HeaderBox>
  );
}

Header.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  eventDetails: PropTypes.shape({
    addToCalender: PropTypes.string.isRequired,
    registerLink: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired
  }),
  isMarketingMaterials: PropTypes.bool
};

Header.defaultProps = {
  eventDetails: null,
  isMarketingMaterials: false
};

export default Header;
