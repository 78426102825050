import { createTheme } from '@mui/material/styles';

const ranTheme = createTheme({
  components: {
    // MuiBackdrop: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'rgba(0,0,0,0.1)'
    //     }
    //   }
    // },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: '2px 2px 7px #D6D6D6'
        }
      }
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: 'none',
    //       backgroundColor: 'transparent'
    //     }
    //   }
    // },
    MuiLink: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          textDecoration: 'none'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          marginLeft: '0',
          marginRight: '10px'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '2px 2px 7px #D6D6D6',
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          border: '1px solid #EBEBEB',
          borderRadius: '10px'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTranform: 'none'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: {
            color: 'primary'
          },
          style: {
            border: '2px solid #FFFFFF',
            borderRadius: '8px',
            color: '#FFFFFF',
            backgroundColor: '#020249',
            textTransform: 'none',
            fontWeight: '600',
            fontSize: '1rem', //16px
            '&:active': {
              border: '2px solid #FFFFFF',
              borderRadius: '8px',
              color: '#020249',
              backgroundColor: '#FFFFFF',
              textTransform: 'none',
              fontWeight: '600'
            },
            '&:hover': {
              border: '2px solid #020249',
              color: '#020249',
              backgroundColor: '#FFFFFF',
              boxShadow: 'none',
              fontWeight: '600'
            },
            '&:disabled': {
              backgroundColor: '#E7E7E4',
              color: '#808080',
              border: '1px solid #E7E7E4',
              cursor: 'not-allowed',
              pointerEvents: 'auto'
            }
          }
        },
        {
          props: {
            color: 'secondary'
          },
          style: {
            border: '1px solid #EBAC5B',
            borderRadius: '8px',
            color: '#EBAC5B',
            backgroundColor: '#ffffff',
            textTranform: 'capitalize',
            '&:hover': {
              backgroundColor: '#000',
              color: '#fff',
              border: '1px solid #ffffff'
            }
          }
        }
      ]
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: '2px solid #FFFFFF',
          borderRadius: '15px',
          color: '#FFFFFF',
          backgroundColor: '#020249',
          marginRight: '10px',
          marginBottom: '10px',
          fontSize: '0.875rem',
          cursor: 'pointer',
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          '&:Hover': {
            border: '2px solid #020249',
              color: '#020249',
              backgroundColor: '#FFFFFF !important'
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          paddingLeft: 20,
          paddingRight: 20
        }
      }
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: '#030330'
    },
    secondary: {
      main: '#f50057'
    }
  },
  typography: {
    // fontSize: 16,
    htmlFontSize: 16,
    // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeightLight: 400,
    h2: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '2rem', //32px
      lineHeight: 1.25,
      fontWeight: 900,
      color: '#383838'
    },
    heroheading: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '2rem', //32px
      lineHeight: 1.25,
      fontWeight: 900,
      color: '#ffffff',
      display: 'block'
    },
    h3: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '1.5rem', //24px
      lineHeight: 1.33,
      fontWeight: 500,
      color: '#383838'
    },
    h4: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '1.3rem', //20px
      lineHeight: 2,
      fontWeight: 500
    },
    h5: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '1rem', //18px
      lineHeight: 1.33,
      color: '#383838'
    },
    heroh5: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '1rem', //18px
      lineHeight: 1.33,
      color: '#ffffff',
      display: 'block',
      textDecoration: 'underline',
      marginBottom: '12px'
    },
    body1: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '0.9375rem', //15px
      lineHeight: 1.46,
      display: 'block'
    },
    herobody1: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '0.9375rem', //15px
      lineHeight: 1.46,
      color: '#ffffff',
      display: 'block'
    },
    cardcategory: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '0.75rem', //12px
      lineHeight: 1.33,
      textTranform: 'uppercase',
      color: '#383838'
    },
    carddate: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '0.75rem', //12px
      lineHeight: 1.33,
      textTranform: 'capitalize',
      color: '#898989'
    },
    herocarddate: {
      // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '0.75rem', //12px
      lineHeight: 1.33,
      textTranform: 'capitalize',
      color: '#ffffff'
    },
    headingdividerline: {
      borderBottom: '4px solid #383838',
      width: '42px',
      display: 'block',
      margin: '15.5px 0',
      borderRadius: '2px'
    },
    heroheadingdividerline: {
      border: '2px solid #ffffff',
      width: '42px',
      display: 'block',
      margin: '15.5px 0',
      borderRadius: '2px'
    }
  }
});

export default ranTheme;
