import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const BackDrop = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '&::after': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    background: '#000',
    opacity: 0.2,
    cursor: 'pointer'
  }
}));

const InputStyled = styled(Input)(({ theme }) => ({
  ...theme.typography.body2,
  width: '100%',
  height: '100px',
  fontSize: '40px'
}));

function SearchBar({ isOpen, close }) {
  const [searchText, setSearchText] = React.useState('');
  const inputRef = React.useRef(null);

  const onFormSubmit = event => {
    event.preventDefault();
    close();
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.replace(`/search/?query=${searchText}`);
    }, 300);
  };

  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.children[0]?.focus();
      }, 100);
    }
  }, [isOpen]);

  return (
    <>
      <Box
        sx={
          { padding: {
            xs: '20px 30px 20px',
            md: '20px 80px 40px'
          },
          backgroundColor: '#fff' }
        }
      >
        <form onSubmit={onFormSubmit} style={{ position: 'relative' }}>
          <IconButton
            onClick={() => close()}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translate(-50%, -50%)',
              color: '#000',
              zIndex: 100
            }}
          >
            <CloseIcon />
          </IconButton>
          <InputStyled
            sx={{
              fontSize: {
                xs: '20px',
                md: '40px'
              },
              height: {
                xs: '50px',
                md: '100px'
              }
            }}
            defaultValue=''
            required
            autoFocus
            fullWidth
            ref={inputRef}
            value={searchText}
            placeholder='Enter search query'
            onChange={event => setSearchText(event.target.value)}
          />
        </form>
      </Box>
      <BackDrop onClick={() => close()} />
    </>
  );
}

SearchBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default SearchBar;
